<template>
  <div class="dialog">
    <div class="typeCheck">
      <span @click="$emit('selectCancel')">取消</span>
      <span>请选择</span>
      <span @click="typeConfirm">确定</span>
    </div>
    <van-checkbox-group class="typeCheckBox" @change="change" v-model="checkedValue">
      <van-cell-group v-for="(item, index) in list" :key="index">
        <van-cell :title="item.name" @click="toggle(index)">
          <template #icon>
            <van-checkbox :name="item" ref="checkboxes" />
            <!-- <van-icon v-if="item.branchVOS !== null" :name="childrenVisible ?'arrow-up':'arrow-down'"
              @click.stop="childrenVisible = !childrenVisible" /> -->
          </template>
        </van-cell>
        <!-- <van-checkbox-group v-if="childrenVisible" class="children" @change="childrenChange" v-model="childrenValue">
          <van-cell-group v-for="(items, i) in item.branchVOS" :key="i">
            <van-cell :title="items.name" @click="toggleChildren(i)">
              <template #icon>
                <van-checkbox :name="items" ref="childrenBoxes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group> -->
      </van-cell-group>
    </van-checkbox-group>
  </div>
</template>

<script>
export default {
  props: ['list'],
  data() {
    return {
      checkedValue: [],
      label: '',
      value: ''
    }
  },
  methods: {
    toggle(index) {
      this.$refs.checkboxes[index].toggle()
    },
    toggleChildren(index) {
      this.$refs.childrenBoxes[index].toggle()
    },
    typeConfirm() {
      this.$emit('selectVal', this.label, this.value)
      this.selectDialogVisible = false
    },
    change(val) {
      this.label = val.map(item => {return item.name}).toString()
      this.value = val.map(item => {return item.id}).toString()
    }
  }
}
</script>
<style lang="scss" scoped>
.typeCheck {
  display: flex;
  height: 150px;
  line-height: 115px;
  padding: 0 40px;
  justify-content: space-between;
  span {
    display: block;
    font-size: 30px;
  }

  span:nth-child(1) {
    font-size: 28px;
    color: #969799;
  }

  span:nth-child(3) {
    font-size: 28px;
    color: #576b95;
  }
}

.typeCheckBox .van-cell__title {
  margin-left: 20px;
}

.typeCheckBox .van-icon {
  position: absolute;
  right: 40px;
  bottom: 45%;
}

.children {
  margin-left: 50px;
}

.children .van-cell__value {
  margin-left: 10px;
}
</style>
