<template>
  <div>
    <van-popup v-model="selectDialogVisible" position="bottom" style="max-height: 50%;">
      <select-dialog :list="selectList" @selectVal="selectVal" @selectCancel="selectDialogVisible = false"></select-dialog>
    </van-popup>
    <!-- 公告类型 -->
    <van-popup v-model="announcementTypeShow" round position="bottom">
      <van-picker show-toolbar title="请选择公告类型" :columns="typeList" value-key="label" @confirm="typeChange"
        @cancel="announcementTypeShow = false" />
    </van-popup>
    <div style="margin-top: 12px">
      <van-cell-group>
        <van-cell>
          <template #title>
            <div class="field-title">
              <span class="custom-title" v-if="!id">新增</span>
              <span class="custom-title" v-if="id">编辑</span>
            </div>
          </template>
        </van-cell>
        <van-field input-align="right" v-model="dataForm.title" required label="公告标题" placeholder="请输入" />
        <van-field input-align="right" v-model="announcementValue" readonly required is-link label="公告类型"
          placeholder="请选择" @click="announcementTypeShow = true" />
        <van-cell title="公告内容" required :border="false" style="padding-bottom: 0"></van-cell>
        <van-field clearable clear-trigger="always" class="left" v-model="dataForm.des" rows="3" autosize
          type="textarea" placeholder="请输入公告内容" input-align="left" :rules="[{ required: true, message: '请输入公告内容' }]" />
        <van-field input-align="right" v-model="subareaValue" readonly is-link label="面向对象小区" placeholder="请选择"
          @click="showSelect(1)" />
        <van-field input-align="right" v-model="buildingValue" readonly is-link label="面向对象楼幢" placeholder="请选择"
          @click="showSelect(2)" />
        <van-field input-align="right" v-model="unitValue" readonly is-link label="面向对象单元" placeholder="请选择"
          @click="showSelect(3)" />
      </van-cell-group>

      <div class="footer-btns">
        <van-button v-if="!readonly" type="info" size="large" @click="submit" color="#387FF5"
          style="border-radius: 10px">确认</van-button>
        <div v-else class="button-group">
          <van-button class="delete" type="info" size="large" style="border-radius: 10px" @click="deleteHandle">删除
          </van-button>
          <van-button class="btn-items" type="info" size="large" color="#387FF5" style="border-radius: 10px"
            @click="submit">完成</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectDialog from './select-dialog'
export default {
  components: {
    SelectDialog
  },
  data() {
    return {
      id: '',
      subareaValue: '',
      buildingValue: '',
      unitValue: '',
      type: '',
      selectDialogVisible: false,
      readonly: false,
      announcementTypeShow: false,
      announcementValue: '',
      dataForm: {
        orgId: this.$orgId,
        orgUserId: this.$globalData.userInfo.userId,
        title: '',
        announcementType: '',
        des: '',
        createClient: '',
        subarea: '',
        building: '',
        unit: ''
      },
      typeList: [],
      selectList: [],
      subAreaList: [],
      buildingList: [],
      unitList: []
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.id = this.$route.query.id || ''
    this.readonly = this.$route.query.readonly || false
    this.getPartyNoticeType()
    if (this.$route.query.readonly) {
      this.getEditIndfo()
    }
  },
  methods: {
    init() {
      this.getSubareaList()
    },
    selectVal (label, value) {
      if (this.type == 1) {
        this.subareaValue = label
        this.dataForm.subarea = value
        if (value.split(',').length == 1) {
          this.getBuildingList(value)
        }
      } else if (this.type == 2) {
        this.buildingValue = label
        this.dataForm.building = value
        if (value.split(',').length == 1) {
          this.getUnitList(value)
        }
      } else if (this.type == 3) {
        this.unitValue = label
        this.dataForm.unit = value
      }
    },
    showSelect(type) {
      this.type = type
      this.selectDialogVisible = true
      if (type == 1) {
        this.selectList = this.subAreaList
      } else if (type == 2) {
        this.selectList = this.buildingList
      } else if (type == 3) {
        this.selectList = this.unitList
      }
    },
    getSubareaList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: this.$orgId
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.subAreaList = data.subAreaList
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getBuildingList(val) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/info/getBySubArea'),
        method: 'post',
        params: this.$http.adornParams({
          subArea: val
        }, false)
      }).then(({ data }) => {
        if (data.code == 0) {
          this.buildingList = data.buildings
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    getUnitList (val) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/unit/listByBuildingId'),
        method: 'post',
        params: this.$http.adornParams({
          buildingId: val
        }, false)
      }).then(({ data }) => {
        if (data.code == 0) {
          this.unitList = data.units
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    // 公告类型
    getPartyNoticeType() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'get',
        params: this.$http.adornParams({
          code: 'partyNotice'
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.typeList = data.dicts
        } else {
          this.$message(data.msg)
        }
      })
    },
    typeChange(val) {
      this.announcementValue = val.label
      this.dataForm.announcementType = val.value
      this.announcementTypeShow = false
    },
    // 编辑时获取编辑信息回显
    getEditIndfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/party/notice/getInfo'),
        method: 'get',
        params: this.$http.adornParams({
          id: this.id
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataForm.orgId = data.appPartyNotice.orgId
          this.dataForm.title = data.appPartyNotice.title
          this.announcementValue = data.appPartyNotice.noticeTypeStr
          this.dataForm.announcementType = data.appPartyNotice.noticeType
          this.dataForm.des = data.appPartyNotice.content
          this.dataForm.createClient = data.appPartyNotice.createClient
        }
      })
    },
    submit() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/party/notice/save'),
        method: 'post',
        data: this.$http.adornData({
          id: this.id,
          title: this.dataForm.title,
          orgId: this.dataForm.orgId,
          noticeType: this.dataForm.announcementType,
          content: this.dataForm.des,
          createClient: this.dataForm.createClient
        }),
        params: this.$http.adornParams({
          orgId: sessionStorage.getItem('orgId'),
          orgUserId: this.dataForm.orgUserId,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.$toast.success({
            message: this.id ? '编辑成功' : '新增成功',
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.$toast.clear()
                this.$router.go(-1)
              }, 1500)
            }
          })
        } else {
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    },
    deleteHandle() {
      var data = [this.id]
      this.$dialog.confirm({
        message: '是否确认删除？'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/wxapp/party/notice/delete'),
          method: 'post',
          data: this.$http.adornData(data, false)
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$toast({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onOpened: () => {
                setTimeout(() => {
                  this.$toast.clear()
                  this.$router.go(-1)
                }, 1500)
              }
            })
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.field-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .edit {
    font-size: 28px;
    font-family: PingFang-Bold;
    font-weight: 500;
    color: #387FF5;
  }
}

.title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 750px;
  height: 88px;
  background: #FFFFFF;
  border-radius: 10px 10px 0px 0px;
  padding: 22px 30px;
  margin-top: 24px;

  .title {
    font-size: 32px;
    font-family: PingFang-Bold;
    font-weight: 500;
    color: #333333;
  }

  .check-more {
    font-size: 24px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: #999999;
  }
}

.info-items {
  width: 750px;
  height: 362px;
  background: #FFFFFF;
  padding: 0 30px;

  .info-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 96px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1000);

    .left-title {
      display: flex;
      font-size: 28px;
      font-weight: 500;
      font-family: PingFang-Bold;

      .communityName {
        color: #666666;
      }

      .partyType {
        color: #333333
      }
    }

    .right-title {
      font-size: 24px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: #999999;
    }
  }

  .info-mid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1000);

    .left-info-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 424px;
      height: 136px;

      .headImg {
        width: 100px;
        height: 136px;
      }

      .detail {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 136px;

        .name {
          font-size: 28px;
          font-family: PingFang-Bold;
          font-weight: 500;
          color: #333333;

          .post {
            color: #666666;
          }
        }

        .number {
          font-size: 28px;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          color: #666666;
        }
      }
    }

    .phoneIcon {
      width: 40px;
      height: 40px;
    }
  }

  .info-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;

    .time {
      font-size: 24px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: #999999;
    }

    .is-show {
      width: 80px;
      height: 52px;
      background: rgba(242, 76, 72, 0.1200);
      border-radius: 8px;
      font-size: 24px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: #387FF5;
      line-height: 52px;
      text-align: center;
    }
  }
}

.footer-btns {
  padding: 0 30px;
  width: 750px;
  height: 88px;
  margin-top: 146px;

  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-items {
      width: 324px;
      height: 88px;
      background: #387FF5;
    }

    .delete {
      width: 324px;
      height: 88px;
      background: linear-gradient(360deg, #FFFFFF 0%, #F5F5F5 100%);
      color: #387FF5;
      border: 2px solid #387FF5;
    }
  }
}
</style>
